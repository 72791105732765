import request from '@/utils/request'

// 客户 分页
export function customPage(query) {
  return request({
    url: '/up/up-custom/page',
    method: 'get',
    params: query
  })
}

// 客户 列表
export function customList(query) {
  return request({
    url: '/up/up-custom/list',
    method: 'get',
    params: query
  })
}

// 客户 详情
export function customInfo(query) {
  return request({
    url: '/up/up-custom/detail',
    method: 'get',
    params: query
  })
}

// 客户 新增
export function customAdd(data) {
  return request({
    url: '/up/up-custom/add',
    method: 'post',
    data
  })
}

// 客户 编辑
export function customEdit(data) {
  return request({
    url: '/up/up-custom/edit',
    method: 'post',
    data
  })
}

// 客户 删除
export function customDel(data) {
  return request({
    url: '/up/up-custom/delete',
    method: 'post',
    data
  })
}
